import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormCloseLivret = ({
  title = 'MR',
  lastName = '',
  firstName = '',
  clientRef = '',
  address = '',
  phone = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Le titulaire,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${address !== '' ? 'completed' : ''}`}>
              Adresse&nbsp;: <span>{address}</span>
            </div>
            <div className={`pdf-form-line ${phone !== '' ? 'completed' : ''}`}>
              Téléphone&nbsp;: <span>{phone}</span>
            </div>
            <p className='pdf-form-line-mention'>
              (En cas de changement d'adresse, nous transmettre en pièce jointe un justificatif de
              domicile de moins de 3 mois)
            </p>
          </div>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Et le co-titulaire éventuel,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Référence&nbsp;client&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Téléphone&nbsp;: <span></span>
            </div>
            <p className='pdf-form-line-mention'>
              (En cas de changement d'adresse, nous transmettre en pièce jointe un justificatif de
              domicile de moins de 3 mois)
            </p>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>demande de clôture du livret</h2>
          <div className='pdf-section-content'>
            <p className='pdf-text'>
              Titulaire d’un Livret DISTINGO, je souhaite procéder à sa clôture.
            </p>
            <br />
            <p className='pdf-text'>J'ai bien noté que&nbsp;:</p>
            <ul className='pdf-list'>
              <li>
                Je peux retirer mes fonds sans clôturer le compte en laissant un solde de 10€
                minimum
              </li>
              <li>
                La clôture de mon livret implique la clôture par anticipation des éventuels Comptes
                à terme
              </li>
              <li>
                La clôture de mon livret implique la clôture des éventuels livrets "mineur" dont je
                suis le représentant légal
              </li>
              <li>
                La clôture de mon livret peut impacter la rémunération de ce dernier si les
                conditions de détention d'une éventuelle offre dont je bénéficie ne sont pas
                respectées.
              </li>
            </ul>
            <br />
            <p className='pdf-text'>
              Je souhaite que le virement de clôture soit effectué sur&nbsp;:
            </p>
            <p className='pdf-text'>
              le compte n°......................................................................
              Banque&nbsp;: ........................................................ déja
              enregistré, ou
            </p>
            <p className='pdf-text'>
              le compte n°......................................................................
              Banque&nbsp;: ........................................................ dont le RIB se
              trouve en pièce jointe
            </p>
            <p className='pdf-form-line-mention'>
              (transmettre le RIB d'un compte d'un établissement de crédit situé en France dont vous
              êtes le titulaire)
            </p>
          </div>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>
              Rappel des conditions de clôture du Livret DISTINGO&nbsp;:
            </span>
            <ul className='pdf-list'>
              <li>Vous recevrez un mail de confirmation de la prise en compte de votre demande</li>
              <li>Votre demande sera traitée à réception et dans un délai maximum de 30 jours</li>
              <li>Une fois votre clôture effectuée, une confirmation vous sera adressée.</li>
            </ul>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>
          <table className='pdf-sign-table smallest double'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
                <td>Signature du co-titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title='demande de clôture'
        title2='Livret Distingo'
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer complétée et signée à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
            <span>ou via la rubrique "clôture" de votre Espace privé</span>
          </React.Fragment>
        }
        formRef='CLOLIV14042025'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormCloseLivret
