import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormCloseCAT = ({ title = 'MR', lastName = '', firstName = '', clientRef = '' }) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>demande de retrait anticipé</h2>
          <div className='pdf-section-content'>
            <p className='pdf-text'>
              Titulaire d’un Compte à terme DISTINGO, je souhaite procéder au retrait anticipé de
              mon Compte à terme,
            </p>
            <div className='section-contrat-montant'>
              <div>
                <p className='pdf-text'>
                  <br />
                  Numéro de contrat<sup className='pdf-sup'>(1)</sup>&nbsp;:
                  ......................................................
                </p>
                <p className='pdf-text space-top'>
                  Numéro de contrat<sup className='pdf-sup'>(1)</sup>&nbsp;:
                  ......................................................
                </p>
                <p className='pdf-text space-top'>
                  Numéro de contrat<sup className='pdf-sup'>(1)</sup>&nbsp;:
                  ......................................................
                </p>
              </div>
              <div>
                <p className='pdf-text'>
                  <br />
                  Montant du capital investi &nbsp;:
                  ......................................................
                </p>
                <p className='pdf-text space-top'>
                  Montant du capital investi &nbsp;:
                  ......................................................
                </p>
                <p className='pdf-text space-top'>
                  Montant du capital investi &nbsp;:
                  ......................................................
                </p>
              </div>
            </div>
            <div className='pdf-checkboxes-inline'>
              <br />
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Je souhaite procéder au retrait anticipé de tous mes Comptes à terme.</label>
              </div>
            </div>

            <p className='pdf-bottom-mention smallest'>
              (1) Vous trouverez votre numéro de Compte à terme sur votre contrat, dans votre Espace
              personnel à la rubrique <strong> «&nbsp;relevés / contrats&nbsp;» </strong> .
            </p>
          </div>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <br />
            <span className='pdf-highlighted'>
              Rappel des conditions de retrait anticipé de mon Compte à terme DISTINGO&nbsp;:
            </span>
            <br />
            <ul className='pdf-list'>
              <li>Dans le mois de l'ouverture du Compte à terme, aucun intérêt ne sera versé</li>
              <li>
                À compter du 2<sup className='pdf-sup'>ème</sup> mois de l'ouverture du Compte, la
                rémunération servie sur la durée effectivement courue est calculée au taux de
                rendement actuariel annuel brut figurant dans les Conditions Générales ou la notice
                d'information précontractuelle du Compte à terme DISTINGO qui vous ont été remises
                lors de l'ouverture de chaque Compte à terme.
              </li>
              <li>
                Conformément aux Conditions Générales du Compte à terme, les fonds seront versés
                exclusivement sur votre Livret d’épargne DISTINGO.
              </li>
              <li>Le retrait anticipé des fonds entraîne la clôture du Compte à terme.</li>
              <li>La demande sera traitée dans un délai maximum de 10 jours.</li>
            </ul>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>
          <table className='pdf-sign-table single'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title='demande de retrait anticipé'
        title2='compte à terme distingo'
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer complétée et signée à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
            <span>ou via la rubrique "clôture" de votre Espace privé</span>
          </React.Fragment>
        }
        formRef='DDRACAT14042025'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormCloseCAT
